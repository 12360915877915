<template>
  <div>
    <div class="card-body pt-0 pb-3 pt-7 card card-custom card-stretch gutter-b">
      <div class="col-xxl-12 order-1 order-xxl-2">
        <div class="tab-content overflow-auto">
          <!--begin::Table-->
          <div class="table-responsive">
            <div class="card-toolbar mb-3">
              <ul class="nav nav-pills nav-pills-sm nav-dark-75">
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    :class="{ active: this.show === 'pending' }"
                    data-toggle="tab"
                    href="#kt_tab_pane_3_1"
                    @click="show = 'pending'"
                  >
                    Pending Scooble Activity
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    data-toggle="tab"
                    :class="{ active: this.show === 'active' }"
                    href="#kt_tab_pane_2_1"
                    @click="show = 'active'"
                  >
                    Active
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    data-toggle="tab"
                    :class="{ active: this.show === 'pending_premium' }"
                    href="#kt_tab_pane_2_1"
                    @click="show = 'pending_premium'"
                  >
                    Pending Premium User Activity
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    data-toggle="tab"
                    :class="{ active: this.show === 'all_premium' }"
                    href="#kt_tab_pane_2_1"
                    @click="show = 'all_premium'"
                  >
                    All Premium User Events
                  </a>
                </li> -->
                <li class="nav-item">
                  <a
                    class="nav-link py-2 px-4 font-weight-bold"
                    data-toggle="tab"
                    :class="{ active: this.show === 'all_event' }"
                    href="#kt_tab_pane_2_1"
                    @click="show = 'all_event'"
                  >
                    All Events
                  </a>
                </li>
              </ul>
            </div>

            <div class="table-responsive">
              <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                <thead>
                  <tr>
                    <th class="pl-0 pt-8"></th>
                    <th class="table-mw-100">Event Image</th>
                    <th style="max-width: 125px" @click="sort('name')">Event Name<i class="fas fa-sort"></i></th>
                    <th class="table-mw-100" @click="sort('startDateTime')">Start Date<i class="fas fa-sort"></i></th>
                    <th class="table-mw-100" @click="sort('endDateTime')">End Date<i class="fas fa-sort"></i></th>
                    <th class="table-mw-100">Max. Participants</th>
                    <th class="table-mw-100">Status</th>
                    <th class="table-mw-100" @click="sort('createdAt')">Created At<i class="fas fa-sort"></i></th>
                    <th class="table-mw-100"></th>
                  </tr>
                </thead>
                <tbody v-if="forceUpdate">
                  <template v-if="show == 'pending'">
                    <sc-events-table
                      @update="onStep1Update"
                      v-for="(item, i) in pending"
                      :key="i"
                      :name="item.name"
                      :startDate="item.startDateTime"
                      :endDate="item.endDateTime"
                      :createdAt="item.createdAt"
                      :participants="item.participantCount"
                      :status="item.status.name"
                      :id="item._id"
                      :item="item"
                    />
                  </template>
                  <template v-else-if="show == 'active'">
                    <sc-events-table
                      @update="onStep1Update"
                      v-for="(item, i) in active"
                      :key="i"
                      :name="item.name"
                      :startDate="item.startDateTime"
                      :endDate="item.endDateTime"
                      :participants="item.participantCount"
                      :status="item.status.name"
                      :id="item._id"
                      :item="item"
                    />
                  </template>
                  <template v-else>
                    <sc-events-table
                      @update="onStep1Update"
                      v-for="(item, i) in list"
                      :key="i"
                      :name="item.name"
                      :startDate="item.startDateTime"
                      :endDate="item.endDateTime"
                      :participants="item.participantCount"
                      :status="item.status.name"
                      :id="item._id"
                      :item="item"
                    />
                  </template>
                </tbody>
              </table>
              <b-pagination
                v-model="currentPage"
                :list="pending"
                :per-page="perPage"
                aria-controls="my-table"
                align="center"
                class="mt-10"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import scEventsTable from '../../content/widgets/advance-table/sc-events-table.vue'
import ApiService from '@/core/services/api.service'
import EventServices from '../../../core/services/Event'

export default {
  name: 'events',
  components: { scEventsTable },
  data() {
    return {
      currentSort: true,
      forceUpdate: true,
      currentPage: 1,
      show: 'pending',
      perPage: 8,
      list: [],
      pending: [],
      active: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Events' }])
    this.getList()
  },
  computed: {
    dataToShow() {
      if (this.show === 'pending') return this.pending
      if (this.show === 'active') return this.active
      // if (this.show === 'pending_premium') return this.pending_premium
      // if (this.show === 'all_premium') return this.all_premium
      if (this.show === 'all_event') return this.list

      return this.event
    },
  },
  methods: {
    getList() {
      ApiService.get('/events/get-all-events').then(({ data }) => {
        this.list = data
        // this.events = data
      })
      ApiService.post('/events/events-by-status', { statusName: 'pending' }).then(({ data }) => {
        console.log(`data`, data)
        this.pending = data
      })
      ApiService.post('/events/events-by-status', { statusName: 'active' }).then(({ data }) => {
        this.active = data
      })
    },
    async onStep1Update(newData) {
      this.forceUpdate = false
      switch (this.show) {
        case 'pending':
          await EventServices.eventDelete(newData).then(({ data }) => {
            if (data.status === 200) {
              const newPendingData = this.pending.filter(v => v._id != newData.eventId)
              this.pending = newPendingData
            }
          })
          break
        case 'active':
          await EventServices.eventDelete(newData).then(({ data }) => {
            if (data.status === 200) {
              const newActiveData = this.active.filter(v => v._id != newData.eventId)
              this.active = newActiveData
            }
          })
          break
        case 'all_event':
          await EventServices.eventDelete(newData).then(({ data }) => {
            if (data.status === 200) {
              const newListData = this.list.filter(v => v._id != newData.eventId)
              this.list = newListData
            }
          })
          break

        default:
          break
      }
      this.$nextTick(() => {
        // Add the component back in
        this.forceUpdate = true
      })
    },
    sort: function (filter) {
      this.currentSort = !this.currentSort
      this.forceUpdate = false

      let modifier = 1
      if (this.currentSort === true) modifier = -1

      const listName = this.show

      this[listName].sort(function (a, b) {
        if (a[filter] === null || b[filter] === null) {
          return -1 * modifier
        }

        const nameA = a[filter].toLowerCase() // ignore upper and lowercase
        const nameB = b[filter].toLowerCase() // ignore upper and lowercase

        if (nameA < nameB) {
          return -1 * modifier
        }
        if (nameA > nameB) {
          return 1 * modifier
        }

        // names must be equal
        return 0
      })

      this.$nextTick(() => {
        // Add the component back in
        this.forceUpdate = true
      })
    },
  },
}
</script>

<style>
.table-mw-150 {
  min-width: 150px;
}
.table-mw-100 {
  min-width: 100px;
}
.mb-25 {
  margin-bottom: 25px !important;
}
</style>
